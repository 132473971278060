import React from "react";
// eslint-disable-next-line import/no-unresolved
import { A11y } from "swiper";
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";

import CardProduct from "./CardProduct";

const ProductSlider = ({ data }) => {
  if (data?.length < 1) return null;

  const products = data.map((product) => (
    <SwiperSlide className="h-auto" key={product.node.url}>
      <CardProduct product={product.node} compact />
    </SwiperSlide>
  ));

  return (
    <Swiper
      className="py-4 px-3"
      modules={[A11y]}
      slidesPerView={1.2}
      spaceBetween={24}
      grabCursor
      breakpoints={{
        768: { slidesPerView: 2.2 },
        992: { slidesPerView: 3.2 },
        1200: { slidesPerView: 4 },
      }}
    >
      {products}
    </Swiper>
  );
};

export default ProductSlider;
