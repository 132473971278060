import React from "react";

import {
  PreparationClock,
  CookTools,
  DifficultyEasy,
  DifficultyMedium,
  DifficultyHard,
} from "../../helpers/Icons";

const RecipeStats = ({ data }) => {
  if (!data) return null;

  const {
    preparation_time: preparationTime,
    cook_time: cookTime,
    servings,
    difficulty_level: difficultyLevel,
  } = data;

  let difficultyIcon;
  switch (difficultyLevel) {
    case "ΕΥΚΟΛΗ":
      difficultyIcon = <DifficultyEasy />;
      break;
    case "ΜΕΤΡΙΑ":
      difficultyIcon = <DifficultyMedium />;
      break;
    case "ΔΥΣΚΟΛΗ":
      difficultyIcon = <DifficultyHard />;
      break;
    default:
      difficultyIcon = null;
  }

  return (
    <>
      {/* ----- Recipe timings ----- */}
      {(preparationTime || cookTime) && (
        <div className="d-flex align-items-center py-2 px-4">
          <PreparationClock />
          <span className="ms-3 lh-sm">
            {preparationTime && (
              <>
                <small>ΠΡΟΕΤΟΙΜΑΣΙΑ&nbsp;</small>
                <strong>{preparationTime}</strong>
              </>
            )}
            {cookTime && (
              <>
                <br />
                <small>ΕΚΤΕΛΕΣΗ&nbsp;</small>
                <strong>{cookTime}</strong>
              </>
            )}
          </span>
        </div>
      )}

      {/* ----- Servings ammount ----- */}
      {servings && (
        <div className="d-flex align-items-center py-2 px-4">
          <CookTools />
          <span className="ms-3 lh-sm">
            <small>ΣΕΡΒΙΡΕΙ&nbsp;</small>
            <strong>{servings}</strong>
          </span>
        </div>
      )}

      {/* ----- Difficulty level ----- */}
      {difficultyIcon && (
        <div className="d-flex align-items-center py-2 px-4">
          {difficultyIcon}
          <span className="ms-3 lh-sm">
            <small>ΒΑΘΜΟΣ ΔΥΣΚΟΛΙΑΣ</small>
            <br />
            <strong>{difficultyLevel}</strong>
          </span>
        </div>
      )}
    </>
  );
};

export default RecipeStats;
