import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useCart } from "react-use-cart";

import { Card, Button } from "react-bootstrap";

import Anchor from "./Anchor";
import RecipeStats from "./RecipeStats";
import { useShop } from "../../context/ShopContext";

import { Currency, truncate } from "../../helpers";

const CardProduct = ({ product, compact, showPrice = true }) => {
  if (!product) return null;

  const { addItem, totalItems, metadata } = useCart();
  const { signUp } = useShop();
  const box = metadata?.box || null;
  const customer = !!metadata?.customer;

  const { title, description, price, media } = product.data;

  const d = description?.text;
  const excerpt = d ? `${truncate(d, 80)}${d.length > 79 ? "..." : ""}` : null;

  const productData = {
    id: product.uid,
    url: product.url,
    title,
    price,
    media,
  };

  return (
    <Card className="shadow h-100">
      {media?.length > 0 && media[0].image && (
        <Anchor href={product.url} title={title}>
          {media[0].image.gatsbyImageData ? (
            <Card.Img
              as={GatsbyImage}
              image={media[0].image.gatsbyImageData}
              alt={media[0].image.alt || title || ""}
              className="bg-light"
            />
          ) : (
            media[0].image.url && <Card.Img src={media[0].image.url} />
          )}
        </Anchor>
      )}
      <Card.Body className="d-flex flex-column pb-0">
        <Card.Title
          as={Anchor}
          href={product.url}
          className="flex-fill h5 mb-3 px-3 text-primary link-unstyled"
        >
          {title}
        </Card.Title>
        {excerpt && <Card.Text className="mb-4 px-3">{excerpt}</Card.Text>}
        {!compact && (
          <>
            <div className="py-3 px-4 px-sm-6 border-top">
              <RecipeStats data={product.data} />
            </div>
            {showPrice && customer && (
              <div className="p-2 fs-5 text-center bg-light">
                <Currency value={price} />
              </div>
            )}
          </>
        )}
      </Card.Body>
      {!compact && (
        <Card.Footer
          className={`bg-transparent px-3${showPrice ? "" : " pb-3"}`}
        >
          {customer ? (
            <Button
              variant={showPrice ? "link" : "primary"}
              className="w-100 fw-bold text-decoration-none"
              onClick={() => addItem(productData)}
              disabled={!box || box.productLimit <= totalItems}
            >
              ΠΡΟΣΘΗΚΗ ΣΤΟ ΚΑΛΑΘΙ
            </Button>
          ) : (
            <Button as={Anchor} href={signUp} className="w-100 mb-2 fw-bold">
              ΕΓΓΡΑΦΗ
            </Button>
          )}
        </Card.Footer>
      )}
    </Card>
  );
};

export default CardProduct;
